<template>
  <g :fill="color" fill-rule="nonzero">
    <path d="M6.344 5.483L4.719 7.022a.5.5 0 1 0 .687.726l1.625-1.539a.5.5 0 1 0-.687-.726z" />
    <path d="M.625 3.1l4.062 4.615a.5.5 0 0 0 .75-.66L1.376 2.438a.5.5 0 1 0-.75.66z" />
    <path
      d="M8.437 1.5v8.462H1.5V7.385a.5.5 0 0 0-1 0v3.577h8.937V.5H3.331a.5.5 0 0 0 0 1h5.106z"
    />
    <path
      d="M12.5 7.248v2.714H8.937a.5.5 0 0 0 0 1H13.5V7.154l-.034-.182-.937-2.395H8.938a.5.5 0 1 0 0 1h2.908l.654 1.671z"
    />
    <path
      d="M9.75 10.055c.668 0 1.219.522 1.219 1.176 0 .654-.55 1.175-1.219 1.175-.668 0-1.219-.52-1.219-1.175s.55-1.176 1.22-1.176zm0 .813c-.23 0-.406.167-.406.363 0 .195.177.363.406.363.23 0 .406-.168.406-.363 0-.195-.177-.363-.406-.363zM3.438 10.055c.668 0 1.218.522 1.218 1.176 0 .654-.55 1.175-1.218 1.175-.669 0-1.22-.52-1.22-1.175s.551-1.176 1.22-1.176zm0 .813c-.23 0-.407.167-.407.363 0 .195.177.363.407.363.229 0 .406-.168.406-.363 0-.195-.177-.363-.406-.363z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
